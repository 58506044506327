import './App.css';
import Leaderboard from './components/Leaderboard'
function App() {

  return (
    <div className="App">
      <nav class="bg-gradient-to-r from-yellow-400 via-yellow-600 to-pink-400 mb-24">
        <div class="container mx-auto px-4 md:px-0 font-sans w-full">
          <div class="relative flex items-center justify-between h-16">
            <span class="text-white  text-2xl">
              <span class="font-bold">talabat </span>
              activities — powered by People Experience
            </span>
          </div>
        </div>
      </nav>
      <div class="container mx-auto">
        <Leaderboard />
      </div>
    </div>
  );
}

export default App;
