import React from 'react';
class Leaderboard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      isLoaded: false,
      items: []
    };
  }

  componentDidMount() {
    fetch("https://europe-west1-bta---talabat.cloudfunctions.net/tlb-data-prod-cloudfunction-tactivities-steps?token=6b6cfc97-bf3c-480e-9f13-ba56e6da652f")
      .then(res => res.json())
      .then(
        (result) => {
          this.setState({
            isLoaded: true,
            items: result.aggregated_values
          });
        },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        (error) => {
          this.setState({
            isLoaded: true,
            error
          });
        }
      )
  }

  render() {
    const { error, isLoaded, items } = this.state;
    if (error) {
      return <div>Error: {error.message}</div>;
    } else if (!isLoaded) {
      return <div>Loading...</div>;
    } else {
      return (
        <div>
        <div className="flex flex-col lg:flex-row lg:h-auto">
          <div className="w-full m-4 bg-gray-100 rounded shadow-lg text-l sm:text-xl text-center block h-full">
            <div class="text-center pt-4 pb-8 cursor-pointer select-none"><p class="text-6xl font-semibold font-yesteryear">Leaderboard</p></div>
            <ul>
              {items.map((item, index) => (
                <>
                  {(() => {
                    if (index === 0) {
                      return (
                        <div class="flex text-2xl sm:text-3xl flex rounded shadow-md bg-white -mx-3 mb-6 h-24">
                          <div class="w-1/4 my-auto font-bold text-yellow-400">
                            <div>1st</div>
                          </div>
                          <div class="w-1/2 my-auto">
                            {item.full_name} <span className="text-xl block">({item.location})</span>
                          </div>
                          <div class="w-1/4 my-auto">{item.step_count} steps</div>
                        </div>
                      )
                    } else if (index >= 1 && index <= 10) {
                      return (
                        <div class="flex h-16">
                          <div class="w-1/4 my-auto font-bold text-sm text-gray-400">
                            <div>
                              {index+1}
                            </div>
                          </div>
                          <div class="w-1/2 my-auto">
                          {item.full_name}
                          </div>
                          <div class="w-1/4 my-auto">{item.step_count} steps</div>
                        </div>
                      )
                    }
                  })()}

                </>
              ))}
            </ul>
            </div>
          </div>
        </div>
      );
    }
  }
}
export default Leaderboard;
